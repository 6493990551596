<template>
  <div class="_bg-default _80w _100vh pb-3 px-3">
    <div class="_100top">
      <list-survey />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ListSurvey from "./listSurvey.vue";
export default {
  name: "managementSurvey",
  components: {
    ListSurvey
  },
  data() {
    return {
      currentPage: 1,
      limit: 10
    };
  },
  computed: {
    ...mapState({})
  },
  mounted() {},
  methods: {}
};
</script>

<style scoped></style>
